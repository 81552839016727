import { DocumentRenderer } from '@keystone-6/document-renderer'
import Text from '@/components/Text'

export default function ThemedDocumentRenderer({
  document,
}: {
  document: any
}) {
  return (
    <DocumentRenderer
      document={document as any}
      renderers={{
        block: {
          paragraph: ({ children }) => <Text variant="body2">{children}</Text>,
        },
      }}
    />
  )
}
